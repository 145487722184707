<template>
  <div class="p-grid card page">
    <div class="p-col-12">
      <div class="p-col-12">
        <Breadcrumb :model="items"/>
      </div>
      <div class="p-d-flex p-col">
        <Button @click="update()" label="添加文章" class="p-mr-2" icon="pi pi-fw pi-plus"/>
        <InputText v-model="title" placeholder="标题名称"/>
        <Dropdown class="p-ml-3" v-model="author" :options="userList" optionLabel="userName"
                  placeholder="作者" option-value="id" :show-clear="true"/>
        <Dropdown class="p-ml-3" v-model="type" :options="typeList" optionLabel="title"
                  placeholder="类别" option-value="id" :show-clear="true"/>
        <Calendar @clearClick="getDataList" onfocus=this.blur() style="width: 22.3%" class="p-ml-3" v-model="time"
                  placeholder="发布日期" dateFormat="yy-mm-dd" showButtonBar="true"/>
        <Button @click="getDataList" icon="pi pi-search" label="查询" class="p-ml-2"/>
      </div>
      <div class="p-p-2">
        <DataTable ref="dt" :value="list">
          <Column field="id" header="序号" :sortable="true" headerStyle="width: 10rem"></Column>
          <Column field="title" header="标题" :sortable="false"></Column>
          <Column field="time" header="发布时间" :sortable="false">
            <template #body="slotProps">
              {{
                new Date(+new Date(slotProps.data.time) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
              }}
            </template>
          </Column>
          <Column field="author" header="作者" :sortable="false">
            <template #body="o">
              {{o.data.userName?o.data.userName:'管理员'}}
            </template>
          </Column>
          <Column field="operation" header="操作" :exportable="false">
            <template #body="slotProps">
              <Button @click="update(slotProps.data)">编辑</Button>
              <Button class="p-button-danger p-ml-1" @click="confirmDeleteProduct(slotProps.data)">删除</Button>
            </template>
          </Column>
        </DataTable>
        <Paginator @page="changePage" :rows="page.size" :totalRecords="total" :rowsPerPageOptions="[5,10,25]"
                   currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计 {totalRecords} 记录"
                   template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"></Paginator>
      </div>
      <Dialog v-model:visible="deleteProductDialog" :style="{width: '350px'}" header="确认" :modal="true">
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
          <span v-if="product">你确认要删除 <b>{{ article.title }}</b>?</span>
        </div>
        <template #footer>
          <Button label="否" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
          <Button label="是" icon="pi pi-check" class="p-button-text" @click="deleteProduct(article.id)"/>
        </template>
      </Dialog>
      <Toast/>
    </div>
    <PropagandaArticles v-if="display" ref="update" v-on:close="diaLogClose"></PropagandaArticles>
  </div>
</template>

<script>
import PropagandaArticles from "@/views/platform/publicityContentManagement/PropagandaArticles";

export default {
  name: "",
  components: {PropagandaArticles},
  data() {
    return {
      total:0,
      page:{
        current:1,
        size:10
      },
      typeList: [],
      type: null,
      title: null,
      author: null,
      time: null,
      display: false,
      selectedPublisher: null,
      date: null,
      items: [
        {label: '宣传内容管理', to: '/articleManagement'},
        {label: '宣传文章管理', to: '/articleManagement'}
      ],
      data: {},
      list: [],
      article: {},
      productDialog: false,
      deleteProductDialog: false,
      product: {},
      filters: {},
      submitted: false,
      selectedProducts: null,
      sendModal: false,
      userList:[]
    }
  },
  created() {
    this.getDataList()
    this.getColumn()
    this.getUserList()
  },
  methods: {
    getUserList() {
      this.$http.get('/publicUser/getExpertList').then((res) => {
        this.userList = res.data
        let admin={userName:'管理员',id:0}
        this.userList.unshift(admin)
        console.log(this.userList)
      })
    },
    getDataList() {
      this.$http.get('/article/getAllArticle',{
        params:{
          current:this.page.current,
          size:this.page.size,
          title:this.title,
          time:this.time,
          author:this.author,
          column:this.type
        }
      }).then((res) => {
        this.list = res.data.records
        this.total =res.data.total
      })
    },
    changePage(event) {
      this.page.size = event.rows
      this.page.current = event.page + 1
      this.getDataList()
    },
    getColumn() {
      this.$http.get('/article/getAllColumn').then((res) => {
        this.typeList = res.data
      })
    },
    update(info) {
      this.display = true
      this.$nextTick(() => {
        this.$refs.update.init(info)
      })
    },

    diaLogClose() {
      this.display = false
      this.getDataList()
    },

    confirmDeleteProduct(article) {
      this.article = article
      this.deleteProductDialog = true;
    },

    deleteProduct(id) {
      this.$http.get("/article/delArticle", {
        params: {
          id: id
        }
      }).then(() => {
        this.deleteProductDialog = false;
        this.$toast.add({severity: 'success', summary: '成功', detail: '删除成功', life: 3000});
        this.getDataList()
      })
    },
  }
}
</script>

<style scoped>

</style>